import { accessToken } from './access-token';
import { Context } from '../utils/store/store';
import { handleCookieAuth } from '../utils/api/auth';
import { useContext, useEffect, useState } from 'react';
import { verifyUserData } from '../utils/data/verifying';

import Routes from '../routes/Routes';
import LoadAnimation from '../components/animations/LoadAnimation';

const CookieAuthMiddleware = () => {
    const [verifyingCookie, setVerifyingCookie] = useState(true);
    const { user: { setUserData } } = useContext(Context) as Store;
    useEffect(() => {
        (async () => {
            const response = await handleCookieAuth();
            const userData = await verifyUserData(response.data?.userData);
            const token = response.data?.token;
            if (userData && token && response.status === 200) {
                accessToken.setValues(userData.uuid, token);
                setUserData(userData);
            }
            setVerifyingCookie(false);
        })()
    }, [setUserData, setVerifyingCookie]);
    return verifyingCookie ? <LoadAnimation /> : <Routes />;
};

export default CookieAuthMiddleware;
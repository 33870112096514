import { useState, createContext } from 'react';
import { handleDeleteCookie } from '../api/auth';

const initUserData: UserData = {
    uuid: '',
    token: '',
    auth: false,
    admin: false,
    first_name: '',
    last_name: '',
    img_uuid: '',
    imgURL: '',
    email: '',
    title: '',
    phone: '',
    org_uuid: '',
    org_name: '',
    logo_url: '',
    org_type: 'producer',
    trunk_id: null,
    regulator_id: null,
    num_producers: 0
};

const initStore: Store = {
    user: {
        userData: initUserData,
        setUserData() {}
    },
    table: {
        tableData: null,
        setTableData() {}
    },
    view: {
        isSectionChanging: false,
        setSectionChanging() {}
    }
};

export const dashboards: Dashboards = [{
        id: 0,
        title: 'Real-time Data',
        param: 'realtime'

    },{
        id: 1,
        title: 'Cumulative Data',
        param: 'cumulative'
    },{
        id: 2,
        title: 'Producer Data',
        param: 'producer'
    }
];

export const Context = createContext<Store>(initStore);

export const storeReset: StoreReset = { start () {} };

const StoreProvider: React.FunctionComponent = ({ children }) => {
    const [userData, setUserData] = useState<UserData>(initUserData);
    const [tableData, setTableData] = useState<TableData[] | null>(null);
    const [isSectionChanging, setSectionChanging] = useState<boolean>(true);
    const store: Store = {
        user: { userData, setUserData },
        table: { tableData, setTableData },
        view: { isSectionChanging, setSectionChanging },
    };
    storeReset.start = () => {
        handleDeleteCookie();
        setTableData(null);
        setUserData(initUserData);
        setSectionChanging(true);
    }
    return (
        <Context.Provider value={store}>
            {children}
        </Context.Provider>
    );
};

export default StoreProvider;
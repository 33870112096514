import { isBooleanType } from '../helpers/data-structures';

const fetchImageTest = async (url: string): Promise<boolean> => {
    try {
        return (await fetch(url, { method: 'HEAD' })).ok;
    } catch (error) {
        return false;
    }
};

const removeDocumentFromURL = (url: string): string | undefined => {
    for (let i = url.length - 1; i > 0; i--) {
        if(url[i] === "/") return url.slice(0, i+1);
    }
};

const checkS3ForUserImage = (logo_URL: string, img_UUID: string): Promise<string> =>
    new Promise(async resolve => {
        if (!logo_URL || !img_UUID) return resolve(logo_URL);
        const baseURL = removeDocumentFromURL(logo_URL);
        const imgURL = baseURL + `${img_UUID}-cropped.png`;
        (await fetchImageTest(imgURL))
            ? resolve(imgURL)
            : resolve(logo_URL);
    });

const isUserDataValid = (userData: UserData): boolean => (
    userData.uuid.length         > 0 &&
    userData.email.length        > 0 &&
    userData.title.length        > 0 &&
    userData.phone.length        > 0 &&
    userData.num_producers       > 0 &&
    userData.org_type.length     > 0 &&
    userData.img_uuid.length     > 0 &&
    userData.org_uuid.length     > 0 &&
    userData.org_name.length     > 0 &&
    userData.logo_url.length     > 0 &&
    userData.last_name.length    > 0 &&
    userData.first_name.length   > 0 &&
    isBooleanType(userData?.admin)
);

export const verifyUserData = async (userData: any): Promise<UserData | null>  => {
    try {
        if (isUserDataValid(userData)) {
            return {
                ...userData,
                auth: true,
                imgURL: await checkS3ForUserImage(userData.logo_url, userData.img_uuid)
            };
        }
        throw new Error("data verification failed");
    } catch (error) {
        return null;
    }
};

export const verifyProducerRtData = (data: any): boolean => {
    if(data?.calc_ts.length <= 0) return false;
    const len = data.calc_ts.length;
    try {
        return (
            data?.spark_data.length    === 14  &&
            data?.spark_data[0].length === len &&
            data?.press_data.length    === 2   &&
            data?.press_data[0].length === len &&
            data?.week_data.length     === 4   &&
            data?.week_data[0].length  === 7
        );
    } catch (error) {
        return false;
    }
};

export const verifyProducerCuData = (data: any): boolean => {
    if(data.length !== 5 || !data[0].length) return false
    const len = data[0].length;
    return data.every((arr: any[]) => arr.length === len);
};
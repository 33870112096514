const isObjectType = (val: any): boolean => Object.prototype.toString.call(val) === '[object Object]';

export const isDateType = (val: any): boolean => Object.prototype.toString.call(val) === '[object Date]';

export const isBooleanType = (val: any): boolean => typeof(val) === 'boolean';

export const isStringType = (val: any): boolean => typeof(val) === 'string';

export const isObjOfItems = (val: any): boolean => isObjectType(val) && Object.keys(val).length > 0;

// export const isArrayOfItems = (val: any): boolean => Array.isArray(val) && val.length > 0;

// export const isArrayOfOnlyObjects = (val: any): boolean => (
//     Array.isArray(val) && val.length > 0 && (val.find(item=>!isObjectType(item)) ? false : true)
// );
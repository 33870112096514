import axios from 'axios';

// "https://api.volued.com/path/resource";
// export const baseURL = "http://127.0.0.1:3000";
export const baseURL = "https://api.volued.com/back";

export const axiosWithCookie = axios.create({
    timeout: 5000,
    baseURL: baseURL,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
    validateStatus (status) {
        return status === 200;
    }
});

export const axiosNoCookie = axios.create({
    timeout: 5000,
    baseURL: baseURL,
    withCredentials: false,
    headers: { 'Content-Type': 'application/json' },
    validateStatus (status) {
        return status === 200;
    }
});

export const axiosS3Upload = axios.create({
    timeout: 600000,
});

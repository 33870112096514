import jwt_decode from "jwt-decode";

export const accessToken = {
    token: "",
    userUUID: "",
    tokenExpiry: 0,
    tokenUserUUID: "",
    getTokenExpiry (token: string): number {
        try {
            return jwt_decode<{exp: number}>(token).exp * 1000;
        } catch (error) {
            return 0;
        }
    },
    getTokenUserUUID (token: string): string {
        try {
            return jwt_decode<{val: string}>(token).val;
        } catch (error) {
            return "";
        }
    },
    isValid (): boolean {
        return (
            this.tokenUserUUID !== ""            &&
            this.userUUID === this.tokenUserUUID &&
            this.tokenExpiry > new Date().getTime()
        )
    },
    reset (): void {
        this.token = "";
        this.userUUID = "";
        this.tokenExpiry = 0;
        this.tokenUserUUID = "";
    },
    setToken (token: string): void {
        if (token.length > 0) {
            this.token = token;
            this.tokenExpiry = this.getTokenExpiry(token);
            this.tokenUserUUID = this.getTokenUserUUID(token);
        }
    },
    setValues (userUUID: string, token: string): void {
        if (userUUID.length > 0 && token.length > 0) {
            this.token = token;
            this.userUUID = userUUID;
            this.tokenExpiry = this.getTokenExpiry(token);
            this.tokenUserUUID = this.getTokenUserUUID(token);
        }
    }
};
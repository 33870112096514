import ReactDOM from 'react-dom';
import { isIE } from 'react-device-detect';
import reportWebVitals from './reportWebVitals';
import StoreProvider from './utils/store/store';

import './assets/scss/styles.scss';
import CookieAuthMiddleware from './auth/CookieAuthMiddleware';

ReactDOM.render(
  isIE 
    ? <div>
        This application does not support Internet Explorer.<br />
        Please consider updating your browser to a latest release of Microsoft Edge, Google Chrome or Mozilla Firefox.<br />
        Microsoft is discontinuing support for Internet Explorer - <a href="https://techcommunity.microsoft.com/t5/microsoft-365-blog/microsoft-365-apps-say-farewell-to-internet-explorer-11-and/ba-p/1591666" target="_blank" rel="noreferrer">more information</a>.<br />
        Thank you, Volued Limited.
      </div>
    : <StoreProvider children={<CookieAuthMiddleware />} />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
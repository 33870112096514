const LoadAnimation = () => {
    return (
        <div className="load-content">
            <div className="load-fading-circle">
                <div className="load-circle1 load-circle"></div>
                <div className="load-circle2 load-circle"></div>
                <div className="load-circle3 load-circle"></div>
                <div className="load-circle4 load-circle"></div>
                <div className="load-circle5 load-circle"></div>
                <div className="load-circle6 load-circle"></div>
                <div className="load-circle7 load-circle"></div>
                <div className="load-circle8 load-circle"></div>
                <div className="load-circle9 load-circle"></div>
                <div className="load-circle10 load-circle"></div>
                <div className="load-circle11 load-circle"></div>
                <div className="load-circle12 load-circle"></div>
            </div>
        </div>
    );
};

export default LoadAnimation;
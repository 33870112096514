import { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoadAnimation from '../components/animations/LoadAnimation';

const SignIn = lazy(()=>import('../components/signin/SignIn'));
const Dashboard = lazy(()=>import('../components/dashboard/Dashboard'));

const Routes = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<LoadAnimation />}>
                <Switch>
                    <Route path="/signin" component={SignIn} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route exact path="/" component={SignIn} />
                    <Route path="*" component={SignIn} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
};

export default Routes;
import { accessToken } from '../../auth/access-token';
import { axiosNoCookie, axiosWithCookie } from './helpers';
import { AxiosError, AxiosResponse } from 'axios';

export const handleCookieAuth = async (): Promise<AxiosResponse<AxiosAuthResponse> | CustomResponseError> => {
    try {
        return await axiosWithCookie.get<AxiosAuthResponse>("/cookie-auth", {
            withCredentials: true
        });
    } catch (error) {
        const axiosError: AxiosError = error;
        return {
            data: null,
            status: axiosError.response?.status || 500,
        } as CustomResponseError;
    }
};

type HandleCredentialsAuth = ({ username, password }: { 
    username: string; 
    password: string; 
}) => Promise<AxiosResponse<AxiosAuthResponse> | CustomResponseError>;

export const handleCredentialsAuth: HandleCredentialsAuth = async ({ username, password }) => {
    try {
        return await axiosWithCookie.post<AxiosAuthResponse>("/credentials-auth", {
            email: username,
            password
        });
    } catch (error) {
        const axiosError: AxiosError = error;
        return {
            data: null,
            status: axiosError.response?.status || 500,
        } as CustomResponseError;
    }
};

export const handleValidateCookie: () => Promise<void> = async () => {
    try {
        const response = await axiosWithCookie.get<{ token: string; }>("/validate-cookie");
        if (response.data.token) {
            accessToken.setToken(response.data.token);
            return;
        }
        accessToken.reset();
    } catch (error) {
        console.log(error)
        accessToken.reset();
    }
};

export const handleDeleteCookie: () => Promise<void> = async () => {
    try {
        await axiosWithCookie.get("/delete-cookie");
    } catch (error) {
        console.log(error);
    }
};

export const handleValidateToken: () => Promise<boolean> = async () => {
    try {
        const response = await axiosNoCookie.post("/validate-token", { 
            token: accessToken.token,
            userUUID: accessToken.userUUID
        });
        if (response.status === 200) return true;
        return false;
    } catch (error) {
        console.log(error)
        return false;
    }
};